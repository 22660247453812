<template>
  <!-- modal Edit-->
  <validation-observer ref="passwordForm" #default="{ handleSubmit }">
    <b-modal
      id="modal-edit-password"
      cancel-variant="outline-secondary"
      ok-title="Submit"
      cancel-title="Close"
      body-class="common_modal"
      centered
      title="Edit Password"
      @ok="handleEdit"
      :ok-disabled="false"
    >
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form @submit.prevent="handleSubmit(handleEdit)" ref="resetPasswordForm">
          <validation-provider
            #default="{ errors }"
            name="Old Password"
            vid="old_password"
            rules="required"
          >
            <b-form-group>
              <label for="old_password">Current Password:</label>
              <b-form-input
                id="old_password"
                type="password"
                placeholder="old_password"
                :state="errors.length > 0 ? false : null"
                v-model="userInfo.old_password"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="{ errors }"
            name="Password"
            vid="old_password"
            rules="required"
          >
            <b-form-group>
              <label for="password">New Password:</label>
              <b-form-input
                id="password"
                type="password"
                placeholder="New Password"
                v-model="userInfo.password"
                :state="errors.length > 0 ? false : null"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <validation-provider
            #default="{ errors }"
            name="Confirm New Password"
            vid="password_confirmation"
            rules="required"
          >
            <b-form-group>
              <label for="name">Confirm New Password:</label>
              <b-form-input
                type="password"
                v-model="userInfo.password_confirmation"
                placeholder="Confirm New Password"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-form>
      </b-overlay>
    </b-modal>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { ref, onMounted } from "@vue/composition-api";
import store from "@/store";
import axiosIns from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BModal,
  BButton,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BOverlay,
} from "bootstrap-vue";

export default {
  components: {
    BModal,
    BButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BOverlay,
  },
  data() {
    return {
      userInfo: { old_password: "", passowrd: "", password_confirmation: "" },
      required,
      loading: false,
    };
  },
  methods: {
    resetForm() {
      this.userInfo = { old_password: "", passowrd: "", password_confirmation: "" };
    },
    hideModal() {
      this.$root.$emit("bv::hide::modal", "modal-edit-passworder", "#btnShow");
    },
    handleLogout() {
      //Request server to void the token
      axiosIns.get("/logout");
      localStorage.clear();
      //Reqired to login page
      //Show the success message
      this.$router.replace("/login").then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Success`,
            icon: "CoffeeIcon",
            variant: "success",
            text: `You have successfully logged out!`,
          },
        });
      });
    },
    handleEdit(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.loading = true;
      axiosIns
        .post("/resetpassword", this.userInfo)
        .then((response) => {
          this.loading = false;
          this.$bvModal.hide("modal-edit-passworder");
          this.$swal({
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          this.handleLogout();
        })
        .catch((error) => {
          this.loading = false;
          const message = error.response.data.message;
          var showMessage = "";
          var showMessage = message;
          if (typeof showMessage === "object") {
            showMessage = "";
            for (var key in message) {
              if (message.hasOwnProperty(key)) {
                showMessage += message[key] + "\n";
              }
            }
          }
          this.$swal({
            text: showMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
  },
};
</script>

<style></style>
